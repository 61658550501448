import VueRouter from 'vue-router'
import Vue from 'vue';
import store from '../store'
import {
  getMenuList,
  shortMenuList,
} from "../utils/menu";
import { isURL } from "@/utils/validate";
import { clearLoginInfo } from "@/utils";
import Login from '@/views/common/login.vue'
import Layout from '@/views/main.vue'
import Home from '@/views/common/home'

import CaseDetails from '@/views/visit/CaseDetails'

import httpAes from '@/utils/httpRequestAes'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {
    path: "/404",
    component: () => import("@/views/common/404"),
    name: "404",
    meta: {
      title: "404未找到"
    }
  },
  
  {
  	  path: '/recordDetail',
  	  name: 'evaluationRecordDetail',
  	   component: () => import("@/views/evaluationRec/recordDetail"),
  	meta: {
  	  title: "记录详情"
  	}
  	},
{
	  path: '/result',
	  name: 'evaluationResult',
	   component: () => import("@/views/evaluationRec/result"),
	meta: {
	  title: "测评报告"
	}
	},
  //默认
  {
    path: "/login",
    component: () => import("@/views/common/login"),
    name: "login",
    meta: {
      title: "登录"
    }
  },

];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  name: 'layout',
  component: Layout,
  redirect: {
    name: 'login'
  },
  children: [
    {
      path: 'home',
      name: 'home',
      component: Home,
      meta: {
        pUrl: 'home', // 添加该字段，需要相应权限
      }
    },
	{
	  path: 'evaluationModel',
	  name: 'evaluationModel',
	   component: () => import("@/views/evaluationModel/evaluationModel"),
	  meta: {
	    title: [
	      {
	        name: "AI测评模型",
	        linkUrl: ""
	      }
	    ],
	    isTab: false,
	    role: 3 | 4
	  }
	},
	{
	  path: "evaluationModel/addModel",
	  component: () => import("@/views/evaluationModel/addModel"),
	  name: "addModel",
	  meta: {
	    title: [{ name: "AI测评模型", linkUrl: "evaluationModel" }, { name: "注册", linkUrl: "" }],
	    isTab: false,
	    role: 3
	  }
	},{
	  path: "evaluationModel/info",
	  component: () => import("@/views/evaluationModel/info"),
	  name: "modelInfo",
	  meta: {
	    title: [{ name: "AI测评模型", linkUrl: "evaluationModel" }, { name: "查看", linkUrl: "" }],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: 'evaluationPackage',
	  name: 'evaluationPackage',
	   component: () => import("@/views/evaluationPackage/index"),
	  meta: {
	    title: [
	      {
	        name: "测评套餐",
	        linkUrl: ""
	      }
	    ],
	    isTab: false,
	    role: 3 | 4
	  }
	},
	{
	  path: 'evaluationPackage/add',
	  name: 'evaluationPackageAdd',
	   component: () => import("@/views/evaluationPackage/add"),
	 meta: {
	   title: [{ name: "测评套餐", linkUrl: "evaluationPackage" }, { name: "添加", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationPackage/update',
	  name: 'evaluationPackageUpdate',
	   component: () => import("@/views/evaluationPackage/update"),
	 meta: {
	   title: [{ name: "测评套餐", linkUrl: "evaluationPackage" }, { name: "编辑", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationPackage/info',
	  name: 'evaluationPackageInfo',
	   component: () => import("@/views/evaluationPackage/info"),
	 meta: {
	   title: [{ name: "测评套餐", linkUrl: "evaluationPackage" }, { name: "详情", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationRec',
	  name: 'evaluationRec',
	   component: () => import("@/views/evaluationRec/index"),
	 meta: {
	   title: [{ name: "测评记录", linkUrl: "evaluationRec" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationRec/evaluationRecDetail',
	  name: 'evaluationRecDetail',
	   component: () => import("@/views/evaluationRec/evaluationRecDetail"),
	 meta: {
	   title: [{ name: "测评记录", linkUrl: "evaluationRec" }, { name: "详情", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	
	{
	  path: 'devicePac',
	  name: 'devicePac',
	   component: () => import("@/views/devicePac/index"),
	 meta: {
	   title: [{ name: "设备套餐维护", linkUrl: "devicePac" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'deviceManage',
	  name: 'deviceManage',
	   component: () => import("@/views/deviceManage/index"),
	 meta: {
	   title: [{ name: "终端设备", linkUrl: "deviceManage" }],
	   isTab: false,
	   role: 3
	 }
	},{
	  path: 'deptManage',
	  name: 'deptManage',
	   component: () => import("@/views/deptManage/index"),
	 meta: {
	   title: [{ name: "机构管理", linkUrl: "deptManage" }],
	   isTab: false,
	   role: 3
	 }
	},{
	  path: 'deptManage/add',
	  name: 'deptManageAdd',
	   component: () => import("@/views/deptManage/add"),
	 meta: {
	   title: [{ name: "机构管理", linkUrl: "deptManage" }, { name: "添加机构", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},{
	  path: 'deptManage/update',
	  name: 'deptManageUpdate',
	   component: () => import("@/views/deptManage/update"),
	 meta: {
	   title: [{ name: "机构管理", linkUrl: "deptManage" }, { name: "编辑机构", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},{
	  path: 'deptManage/info',
	  name: 'deptManageInfo',
	   component: () => import("@/views/deptManage/info"),
	 meta: {
	   title: [{ name: "机构管理", linkUrl: "deptManage" }, { name: "机构详情", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	
	{
	  path: 'deviceManage/add',
	  name: 'deviceManageAdd',
	   component: () => import("@/views/deviceManage/add"),
	 meta: {
	   title: [{ name: "终端设备", linkUrl: "deviceManage" }, { name: "批量添加设备", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'deviceManage/update',
	  name: 'deviceManageUpdate',
	   component: () => import("@/views/deviceManage/update"),
	 meta: {
	   title: [{ name: "终端设备", linkUrl: "deviceManage" }, { name: "批量添加设备", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'deviceManage/info',
	  name: 'deviceManageInfo',
	   component: () => import("@/views/deviceManage/info"),
	 meta: {
	   title: [{ name: "终端设备", linkUrl: "deviceManage" }, { name: "设备详情", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'org',
	  name: 'org',
	   component: () => import("@/views/org/org-users"),
	 meta: {
	   title: [{ name: "机构与用户关系", linkUrl: "org" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'role',
	  name: 'role',
	   component: () => import("@/views/role/role"),
	 meta: {
	   title: [{ name: "角色管理", linkUrl: "role" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'role/add',
	  name: 'roleAdd',
	   component: () => import("@/views/role/role-add"),
	 meta: {
	     title: [{ name: "角色管理", linkUrl: "role" }, { name: "添加角色", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'role/edit',
	  name: 'roleEdit',
	   component: () => import("@/views/role/role-edit"),
	 meta: {
	     title: [{ name: "角色管理", linkUrl: "role" }, { name: "编辑角色", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'role/info',
	  name: 'roleInfo',
	   component: () => import("@/views/role/role-info"),
	 meta: {
	     title: [{ name: "角色管理", linkUrl: "role" }, { name: "查看角色", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationPackage/setting',
	  name: 'evaluationPackageSetting',
	   component: () => import("@/views/evaluationPackage/setting"),
	 meta: {
	   title: [{ name: "测评套餐", linkUrl: "evaluationPackage" }, { name: "测评结果配置", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	{
	  path: 'evaluationPackage/setting',
	  name: 'evaluationPackageSettingDetail',
	   component: () => import("@/views/evaluationPackage/setting"),
	 meta: {
	   title: [{ name: "测评套餐", linkUrl: "evaluationPackage" }, { name: "测评结果配置", linkUrl: "" }],
	   isTab: false,
	   role: 3
	 }
	},
	// 问卷管理
	{
	  path: "dataCollect",
	  component: () => import("@/views/dataCollect/index"),
	  name: "dataCollect",
	  meta: {
	    title: [{ name: "问卷库", linkUrl: "" }],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: "dataCollect/add",
	  component: () => import("@/views/dataCollect/add"),
	  name: "dataCollectAdd",
	  meta: {
	    title: [
	      { name: "问卷库", linkUrl: "dataCollect" },
	      { name: "添加问卷", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: "dataCollect/add",
	  component: () => import("@/views/dataCollect/add"),
	  name: "dataCollectEdit",
	  meta: {
	    title: [
	      { name: "问卷库", linkUrl: "dataCollect" },
	      { name: "编辑问卷", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: "dataCollect/info",
	  component: () => import("@/views/dataCollect/info"),
	  name: "indicatorInfo",
	  meta: {
	    title: [
	      { name: "问卷库", linkUrl: "dataCollect" },
	      { name: "问卷管理", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: "dataCollect/formInfo",
	  component: () => import("@/views/dataCollect/formInfo"),
	  name: "formInfo",
	  meta: {
	    title: [
	      { name: "问卷库", linkUrl: "dataCollect" },
	      { name: "问卷管理", linkUrl: "indicatorInfo" },
	      { name: "查看详情", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3,
	    remark: 2
	  }
	},
	{
	  path: "dataCollect/preview",
	  component: () => import("@/views/dataCollect/preview"),
	  name: "preview",
	  meta: {
	    title: [
	      { name: "问卷库", linkUrl: "dataCollect" },
	      { name: "预览问卷", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3
	  }
	},
	{
	  path: "dataCollect/record",
	  component: () => import("@/views/dataCollect/record"),
	  name: "indicatorRecord",
	  meta: {
	    title: [
	      { name: "问卷管理", linkUrl: "dataCollect" },
	      { name: "问卷记录", linkUrl: "" }
	    ],
	    isTab: false,
	    role: 3
	  }
	},
  
 
  
    {
      path: "account/account-info",
      component: () => import("@/views/account/account-info"),
      name: "account-info",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
	{
	  path: "chat/chat",
	  component: () => import("@/views/chat/chat"),
	  name: "chat",
	  meta: {
	    title: [{ name: "聊天消息", linkUrl: "" }],
	    isTab: false,
	    role: 3 || 4
	  }
	},
	
    {
      path: "account/pwd-change",
      component: () => import("@/views/account/pwd-change"),
      name: "pwd-change",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
    {
      path: "pwd-change",
      component: import("@/views/account/pwd-change"),
      name: "pwd-change",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
    /**
     * 个人信息
     */
    /**
     * 随访
     */
 
  
   
  
 
    {
      path: "custom/group",
      component: () => import("@/views/custom/group/group"),
      name: "group",
      meta: {
        title: [{ name: "分组管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "custom/teamList",
      component: () => import("@/views/team/teamList"),
      name: "teamList",
      meta: {
        title: [{ name: "工作室管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "team/memberAdd",
      component: () => import("@/views/team/memberAdd"),
      name: "memberAdd",
      meta: {
        title: [{ name: "成员管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    //运营菜单
    {
      path: "gzs/teamList",
      component: () => import("@/views/gzs/teamList"),
      name: "gzsList",
      meta: {
        title: [{ name: "工作室管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "gzs/memberAdd",
      component: () => import("@/views/gzs/memberAdd"),
      name: "gzsMemberAdd",
      meta: {
        title: [{ name: "成员管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dict/list",
      component: () => import("@/views/dict/list"),
      name: "dictList",
      meta: {
        title: [{ name: "字典表", linkUrl: "" }],
        name: "字典表",
        isTab: false,
        auth: true,
        ename: "dictList"
      }
    },
    {
      path: "dict/nrfl",
      component: () => import("@/views/dict/nrfl"),
      name: "dict/nrfl",
      meta: {
        title: [{ name: "病种管理", linkUrl: "" }],
        name: "病种管理",
        isTab: false,
        auth: true,
        ename: "nrfl"
      }
    },
    {
      path: "medicine/medicineList",
      component: () => import("@/views/medicine/medicineList"),
      name: "medicine/medicineList",
      meta: {
        title: [{ name: "药品管理", linkUrl: "" }],
        name: "药品管理",
        isTab: false,
        auth: true,
        ename: "medicineList"
      }
    },
    {
      path: "medicine/medicineAdd",
      component: () => import("@/views/medicine/medicineAdd"),
      name: "medicineAdd",
      meta: {
        title: [
          { name: "药品管理", linkUrl: "medicine/medicineList" },
          { name: "药品添加", linkUrl: "" }
        ],
        name: "药品管理",
        isTab: false,
        auth: true,
        ename: "medicineAdd"
      }
    },
    {
      path: "medicine/medicineEdit",
      component: () => import("@/views/medicine/medicineEdit"),
      name: "medicineEdit",
      meta: {
        title: [
          { name: "药品管理", linkUrl: "medicine/medicineList" },
          { name: "药品编辑", linkUrl: "" }
        ],
        name: "药品管理",
        isTab: false,
        auth: true,
        ename: "medicineEdit"
      }
    },
    {
      path: "medicine/medicineInfo",
      component: () => import("@/views/medicine/medicineInfo"),
      name: "medicineInfo",
      meta: {
        title: [
          { name: "药品管理", linkUrl: "medicine/medicineList" },
          { name: "药品详情", linkUrl: "" }
        ],
        name: "药品管理",
        isTab: false,
        auth: true,
        ename: "medicineInfo"
      }
    },


    // 数据采集
    {
      path: "feedback/program",
      component: () => import("@/views/feedback/plan/plan"),
      name: "program",
      meta: {
        title: [{ name: "方案内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/program/info",
      component: () => import("@/views/feedback/plan/info"),
      name: "",
      meta: {
        title: [{ name: "方案反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/missionary",
      component: () => import("@/views/feedback/missionary/missionary"),
      name: "missionary",
      meta: {
        title: [{ name: "宣教内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/missionary/info",
      component: () => import("@/views/feedback/missionary/info"),
      name: "missionary",
      meta: {
        title: [{ name: "宣教反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp",
      component: () => import("@/views/feedback/followUp/followUp"),
      name: "followUp",
      meta: {
        title: [{ name: "问卷内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp/info",
      component: () => import("@/views/feedback/followUp/info"),
      name: "followUpInfo",
      meta: {
        title: [{ name: "问卷反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp/record",
      component: () => import("@/views/feedback/followUp/record"),
      name: "followUpRecord",
      meta: {
        title: [{ name: "问卷反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    // 分组管理
    {
      path: "group",
      component: () => import("@/views/group/index"),
      name: "groupList",
      meta: {
        title: [{ name: "分组管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "group/autoGroup",
      component: () => import("@/views/group/autoGroup"),
      name: "autoGroup",
      meta: {
        title: [
          { name: "分组管理", linkUrl: "groupList" },
          { name: "自动入组设置", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "quickconfig",
      component: () => import("@/views/quickconfig/index"),
      name: "quickconfig",
      meta: {
        title: [
          { name: "快捷入口配置", linkUrl: "quickconfig" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "basic/account-info",
      component: () => import("@/views/basic/index"),
      name: "basic/account-info",
      meta: {
        title: [
          { name: "基本信息配置", linkUrl: "basic" },
        ],
        isTab: false,
        role: 3
      }
    },

  
   
  ],

  beforeEnter(to, from, next) {
    let token = localStorage.getItem("token");
    if (!token || !/\S/.test(token)) {
      clearLoginInfo();
      next({
        name: "login"
      });
    }
    next();
  }
};
const router = new VueRouter({
  mode: "hash",
  // scrollBehavior: () => ({
  //   y: 0
  // }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
});
router.beforeEach((to, from, next) => {
  console.log("to.name", to.name);
 
  if (to.name == "login") {
    localStorage.setItem("login", "login");
    document.title = "精神卫生检查系统";

  } 

  let user = JSON.parse(localStorage.getItem("userInfo"));
if (to.name == 'home'||to.name == 'addModel'||to.name == 'modelInfo'||to.name == 'evaluationPackageAdd'
||to.name == 'evaluationPackageUpdate'||to.name == 'evaluationPackageInfo'||to.name == sessionStorage.lastToName) {
sessionStorage.lastToName = to.name
			httpAes({
				url: httpAes.adornUrl('permission/list'),
				method: 'post',
				data: {intParam1:'1'},
					contentType:'json',
					headers: {
						'Content-Type': 'application/json',
					   				
					},
			}).then(({
				data
			}) => {
				console.log(data, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
				if (data && data.status) {
					let buttonArr = []
				
						store.commit('common/updateMenuList', data.list)
							getButton( JSON.parse(JSON.stringify( data.list)), buttonArr)
					changeData(data.list)
					let menuArr = []
					for (let i = 0; i < data.list.length; i++) {
						menuArr.push(data.list[i].name)
						
	
					}
					sessionStorage.setItem('menuArr', JSON.stringify(menuArr))
					sessionStorage.setItem('buttonArr', JSON.stringify(buttonArr))
					fnAddDynamicMenuRoutes(data.list)
					router.options.isAddDynamicMenuRoutes = true
					
					sessionStorage.setItem('menuList', JSON.stringify(data.list || '[]'))
					sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
				 	
					next({
						...to,
						replace: true
					})
				} else {
					sessionStorage.setItem('menuList', '[]')
					sessionStorage.setItem('permissions', '[]')
					next()
				}
			}).catch((e) => {
				console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
				router.push({
					name: 'login'
				})
			})
		}
			sessionStorage.lastToName = to.name
		next()




  if (to.name) {
    store.commit("common/updateMenuActiveName", to.name);
    if (to.name == 'case-details') {
      store.commit("common/updateMenuActiveName", 'case');
      store.commit("common/updateParentMenuActiveName", "case");
    }
    if (to.name == 'addCaseSearch') {
      store.commit("common/updateMenuActiveName", "caseSearch");
    }
    if (to.name == 'followUpAdd' || to.name == 'followUpDone' || to.name == 'followUpInfo') {
      store.commit("common/updateMenuActiveName", 'followUpList');
      store.commit("common/updateParentMenuActiveName", "followUpList");
    }
    if (to.name == 'dataCollectAdd' || to.name == 'dataCollectEdit' || to.name == 'indicatorInfo'
      || to.name == 'formInfo' || to.name == 'preview') {
      store.commit("common/updateMenuActiveName", "dataCollect");
      store.commit("common/updateParentMenuActiveName", "zykgl");
    }
    if (to.name == 'planAdd' || to.name == 'planEdit' || to.name == 'planInfo') {
      store.commit("common/updateMenuActiveName", 'plan');
      store.commit("common/updateParentMenuActiveName", "zykgl");
    }
    if (to.name == 'missionAdd' || to.name == 'missionEdit' || to.name == 'missionInfo') {
      store.commit("common/updateMenuActiveName", 'mission');
      store.commit("common/updateParentMenuActiveName", "zykgl");
    }
    if (to.name == 'publicAdd' || to.name == 'publicEdit' || to.name == 'publicInfo') {
      store.commit("common/updateMenuActiveName", 'public');
      store.commit("common/updateParentMenuActiveName", "public");
    }
    if (to.name == 'publicAdd2' || to.name == 'publicEdit2' || to.name == 'publicInfo2') {
      store.commit("common/updateMenuActiveName", 'public2');
      store.commit("common/updateParentMenuActiveName", "public");
    }
    if (to.name == 'publicAdd3' || to.name == 'publicEdit3' || to.name == 'publicInfo3') {
      store.commit("common/updateMenuActiveName", 'public3');
      store.commit("common/updateParentMenuActiveName", "public");
    }
    if (to.name == 'noticeAdd') {
      store.commit("common/updateMenuActiveName", 'notice');
      store.commit("common/updateParentMenuActiveName", "notice");
    }
    if (to.name == 'sendsformAdd' || to.name == 'sendsformEdit' || to.name == 'sendsformInfo') {
      store.commit("common/updateMenuActiveName", 'sendsform');
      store.commit("common/updateParentMenuActiveName", "studio");
    }

    if (to.name == 'case-create' || to.name == 'invite' || to.name == 'analysis') {
      store.commit("common/updateParentMenuActiveName", "case");
    }
    if (to.name == 'dataCollect' || to.name == 'plan' || to.name == 'mission') {
      store.commit("common/updateParentMenuActiveName", "zykgl");
    }
    if (to.name == 'public' || to.name == 'public2' || to.name == 'public3') {
      store.commit("common/updateParentMenuActiveName", "public");
    }
    if (to.name == 'label' || to.name == 'group') {
      store.commit("common/updateParentMenuActiveName", "custom");
    }
    if (to.name == 'basic' || to.name == 'quickconfig' || to.name == 'sendsform') {
      store.commit("common/updateParentMenuActiveName", "studio");
    }
  }
  // if (
  //   fnCurrentRouteType(to, globalRoutes) === "global" ||
  //   router.options.isAddDynamicMenuRoutes
  // ) {
  //   next();
  // } else {

  // }
});


function changeData(arr) {
	for (var i = 0; i < arr.length; i++) {
		arr[i].list = arr[i].children
		arr[i].menuId = arr[i].id
		arr[i].url = arr[i].resourceUrl
		if (arr[i].children != null && arr[i].children.length != 0) {
			changeData(arr[i].children)
		}
	}
	console.log(123,arr)
}

function getButton(arr, buttonArr) {
	for (var i = 0; i < arr.length; i++) {

		if (arr[i].children.length != 0 || arr[i].name == '熵孵化') {

			for (var j = 0; j < arr[i].children.length; j++) {
				if (arr[i].children[j].children.length != 0) {
					for (var k = 0; k < arr[i].children[j].children.length; k++) {
						if (arr[i].children[j].children[k].handle != null) {
							buttonArr.push(arr[i].children[j].children[k].handle)
							arr[i].children[j].children.splice(k, 1)
							k--
						}
					}
				} else if (arr[i].children[j].handle != null) {
					buttonArr.push(arr[i].children[j].handle)
					arr[i].children.splice(j, 1)
					j--
				}
			}
		} else if (arr[i].handle != null) {
			buttonArr.push(arr[i].handle)

			arr.splice(i, 1)
			i--
		}
	}
}
/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
  var temp = [];
  console.log(route);
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return "global";
    } else if (
      globalRoutes[i].children &&
      globalRoutes[i].children.length >= 1
    ) {
      temp = temp.concat(globalRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : "main";
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
	console.log(menuList)
	console.log(routes)
	var temp = []
	for (var i = 0; i < menuList.length; i++) {
		if (menuList[i].list && menuList[i].list.length >= 1) {
			temp = temp.concat(menuList[i].list)
		} else if (menuList[i].url && /\S/.test(menuList[i].url)) {
			menuList[i].url = menuList[i].url.replace(/^\//, '')
			var route = {
				path: menuList[i].url,
				component: null,
				name: menuList[i].url,
				meta: {
					menuId: menuList[i].menuId,
					title: menuList[i].name,
					isDynamic: true,
					isTab: false,
					iframeUrl: ''
				}
			}
			// url以http[s]://开头, 通过iframe展示
			if (isURL(menuList[i].url)) {
				route['path'] = `i-${menuList[i].menuId}`
				route['name'] = `i-${menuList[i].menuId}`
				route['meta']['iframeUrl'] = menuList[i].url
			} else {
				try {

					// route['component'] = _import(`modules/${menuList[i].url}`) || null
					route['component'] = _import(menuList[i].url) || null
				} catch (e) { }
			}
			routes.push(route)
		}
	}
	if (temp.length >= 1) {
		fnAddDynamicMenuRoutes(temp, routes)
	} else {
		mainRoutes.name = 'main-dynamic'
		mainRoutes.children = routes
		router.addRoutes([
			mainRoutes,
			{
				path: '*',
				redirect: {
					name: '404'
				}
			}
		])
		sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
		console.log('\n')
		console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
		console.log(mainRoutes.children)
		console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
	}

}

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  console.log('window.location.search', window.location.search)
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
    context = decodeURIComponent(r[2]);
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;

}

export default router;