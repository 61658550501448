<template>
  <main class="site-content">
  
    <el-card
      class="el-card-set-width"
      :body-style="siteContentViewHeight"
      :class="[
        url.indexOf('home') != -1 ? 'bg ' : '',
        $route.name == 'queue'||$route.name == 'case-details' ? 'noPaddTop' : '',
      ]"
    >
 
      <router-view />
     
    </el-card>
  </main>
</template>

<script>
import Bus from "@/utils/bus";
import { isURL } from "@/utils/validate";
export default {
  inject: ["refresh"],
  data() {
    return {};
  },
  components: {},
  computed: {
    url: {
      get() {
        return this.$route.path;
      },
    },
    addRoleFlag: {
      get() {
        return this.$store.state.common.addRoleFlag;
      },
      set(val) {
        this.$store.commit("common/updateAddRoleFlag", val);
      },
    },
    roleInfoFlag: {
      get() {
        return this.$store.state.common.roleInfoFlag;
      },
      set(val) {
        this.$store.commit("common/updateRoleInfoFlag", val);
      },
    },
    roleEditFlag: {
      get() {
        return this.$store.state.common.roleEditFlag;
      },
      set(val) {
        this.$store.commit("common/updateRoleEditFlag", val);
      },
    },
    accountInfoFlag: {
      get() {
        return this.$store.state.common.accountInfoFlag;
      },
      set(val) {
        this.$store.commit("common/updateAccountInfoFlag", val);
      },
    },
    pwdChangeFlag: {
      get() {
        return this.$store.state.common.pwdChangeFlag;
      },
      set(val) {
        this.$store.commit("common/updatePwdChangeFlag", val);
      },
    },
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      },
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      },
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      },
    },
    siteContentViewHeight() {
      var height = this.documentClientHeight - 100 - 30 - 45;
      if (this.$route.meta.isTab) {
        height -= 40;
        return isURL(this.$route.meta.iframeUrl)
          ? {
              height: height + "px",
            }
          : {
              minHeight: height + "px",
            };
      }
      return {
        minHeight: height + "px",
      };
    },
  },
  created() {
    Bus.$on("getTarget", (target) => {
      this.tabsCloseAllHandle();
    });
  },
  methods: {
    // tabs, 选中tab
    selectedTabHandle(tab) {
      tab = this.mainTabs.filter((item) => item.name === tab.name);
      if (tab.length >= 1) {
        this.$router.push({
          name: tab[0].name,
          query: tab[0].query,
          params: tab[0].params,
        });
      }
    },
    // tabs, 删除tab
    removeTabHandle(tabName) {},
    // tabs, 关闭当前
    tabsCloseCurrentHandle() {
      this.removeTabHandle(this.mainTabsActiveName);
    },
    // tabs, 关闭其它
    tabsCloseOtherHandle() {
      this.mainTabs = this.mainTabs.filter(
        (item) => item.name === this.mainTabsActiveName
      );
    },

    // tabs, 关闭全部
    tabsCloseAllHandle() {
      this.mainTabs = [];
      this.menuActiveName = "";
      this.$router.push({
        name: "home",
      });
    },
    // tabs, 刷新当前
    tabsRefreshCurrentHandle() {
      var tab = this.$route;
      this.removeTabHandle(tab.name);
      this.$nextTick(() => {
        this.$router.push({
          name: tab.name,
          query: tab.query,
          params: tab.params,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.el-card__body {
  /*  padding: 20px 40px !important; */
}
.el-card-set-width {
  &.noPaddTop {
    .el-card__body {
      padding: 0;
    }
  }
}
.btn-custom-cancel {
  float: right;
  margin-left: 10px;
}
.site-content__wrapper {
  display: flex;
  position: relative;
  padding-top: 100px;
  min-height: 100%;
  background: #f1f4f5;
  align-items: stretch !important;
}
</style>
