import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import { clearLoginInfo } from '@/utils'
import { Message, Loading } from 'element-ui'
import Global from '@/utils/global'

let loading = {}
var num = 0 //记录请求数量 只打开一个loading
// 每次请求携带cookies信息
//axios.defaults.withCredentials =
//axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=gbk'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	console.log(config)
 config.headers['token'] = localStorage.getItem('token') // 请求头带上token
  num++
  // openFullScreen()
  return config
}, error => {
  Message.error({
    message: '加载超时'
  })
  return Promise.reject(error);
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  num--
  // 判断所有请求是否结束
  if (num <= 0) {
    // 关闭loading
    // loading.close()
  }
  if (response.data.data != undefined && response.data.data.errorcode != undefined) {
    if (response.data.data.errorcode == '401' || response.data.data.errorcode == '404') { // 401, token失效
      clearLoginInfo()
      Message({
        showClose: true,
        message: response.data.msg,
        type: 'error',
        duration: 2000
      })
      router.push({ name: 'login' })
    } else if (response.data.data.errorcode == '500') {
      Message({
        showClose: true,
        message: '服务繁忙，请稍后再试',
        type: 'error',
        duration: 2000
      })
    }
  } else {
    return response
  }


}, error => {
  // loading.close()
  if (error.response.status == 401) { // 401, token失效
    clearLoginInfo()
    router.push({ name: 'login' })
  } else if (error.response.status == 500 || error.response.status == 504) {
    Message({
      showClose: true,
      message: '服务繁忙，请稍后再试',
      type: 'error',
      duration: 2000
    })
  }
  return Promise.reject(error)
})
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // console.log((process.env.NODE_ENV !== 'production' ? '/proxyApi' : window.SITE_CONFIG.baseUrl) + actionName)
  return Global.baseURL + actionName
}
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
// http.adornUrl = (actionName) => {
//   // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
//   return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName,
// 	
// }

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

function openFullScreen() {
  loading = Loading.service({
      lock: true,
      // text: "正在连接",
      // spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
  });
}

export default http
