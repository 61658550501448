<template>
	<div>
		<div  v-bind:class="{loginBag: !mobileFlag,}" class="login">
			<div  style="width: 52%;"></div>
			<div class="login-box">

			
	

				
						
								<div class="jsws">精神卫生诊断系统</div>
								<div class="yhdl">用户登录</div>
					
								<div  class="inputArea">
									<img class="icon" src="../../assets/img/login/name.png" mode=""></img>
									
									<input style="background: #fff;" v-model="dataForm.account" placeholder="账户" type="text" />
								</div>
								<div  class="inputArea">
									<img class="icon" src="../../assets/img/login/pwd.png" mode=""></img>
									
									<input style="background: #fff;" v-model="dataForm.password" placeholder="密码" type="password" />
								</div>
						
								<div class="jzmm">
									  <el-checkbox v-model="jzmm">记住密码</el-checkbox>
								<!-- <checkbox class="myCheckbox" :checked="jzmm" value="jzmm" style="transform:scale(0.5)" />
								<span>记住密码</span>	 -->		
								</div>
								<div @click="getCaptcha" class="loginButton">立即登录</div>
						
				


		</div>
	

		</div>
		<div v-if="!mobileFlag" class="bottom">苏ICP备2021034617号-1</div>
		<div v-if="!mobileFlag" class="bottom2">©Copyright 2023 南京纳实医学科技有限公司</div>
	</div>
</template>

<script>
import Qs from "qs";
var regPhone = /^1[34578]\d{9}$/,
  regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
import { getUUID } from "../../utils/index";
import MD5 from "js-md5";
// 腾讯滑块验证(新建一个js文件)
import TencentCaptcha from "@/assets/TencentCaptcha";
	import cryptoJs from 'crypto-js'
	

import { decrypt, encrypt,decryptPair ,encryptPair,genKey} from "@/utils/aes";

	export default {
		data() {
		    /*var validatePhone = (rule, value, callback) => {
		        if (!value) {
		          callback(new Error('请输入手机号'));
		        } else if (!regPhone.test(value)) {
		          callback(new Error('手机号格式不正确'));
		        } else {
		          callback();
		        }
		      }*/
		    var validatePwd = (rule, value, callback) => {
		      if (!value) {
		        callback(new Error("请输入密码"));
		      } /*else if (!regPwd.test(value)){
		          callback(new Error('4-20位，字母、数字或_'));
		        }*/ else {
		        callback();
		      }
		    };
		    return {
		      usernameText: "", //用户名文本
		      passwordText: "", //记住密码文本
			  jzmm:true,
		      dataForm: {
		        account: "",
		        password: "",
		        //codeImg: ''
		      },
		      dataRule: {
		        account: [
		          { required: true, message: "请输入用户名/手机号", trigger: "blur" },
		        ],
		        password: [{ required: true, validator: validatePwd, trigger: "blur" }],
		        code: [
		          { required: true, message: "请输入图形验证码", trigger: "blur" },
		        ],
		      },
		      userInfo: {},
		      loading: false,
		      uuid: "",
		      appId: "2036675646",
		      // doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
		      unionName: "",
		    };
		  },
		computed: {
			isCanSendCode() { //只有手机号和密码都输入获取验证码可点
				return regPhone.test(this.dataForm.mobile);
			},
			uuid() {
				return getUUID()
			}
		},
		created() {
			var sUserAgent = navigator.userAgent;
			if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -
				1) {
				this.mobileFlag = true
			}
		},
		mounted() {
			if(sessionStorage.dataForm){
				this.dataForm = JSON.parse(sessionStorage.dataForm)
				
			}
	
		},
		methods: {
			encryptDes (message, key = 'xxxxxxxxxxxxxxxxxxxxx') {
			    const keyHex = cryptoJs.enc.Utf8.parse(key)
			    const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 }
			    const encrypted = cryptoJs.DES.encrypt(message, keyHex, option)
			    return encrypted.ciphertext.toString() // 返回hex格式密文，如需返回base64格式：encrypted.toString()
			  },
			   decryptDes (message, key = 'xxxxxxxxxxxxxx') {
			      const keyHex = cryptoJs.enc.Utf8.parse(key)
			      const decrypted = cryptoJs.DES.decrypt(
			        {
			          ciphertext: cryptoJs.enc.Hex.parse(message)
			        },// 若message是base64格式，则无需转16进制hex，直接传入message即可
			        keyHex,
			        {
			          mode: cryptoJs.mode.ECB,
			          padding: cryptoJs.pad.Pkcs7
			        }
			      )
			      return decrypted.toString(cryptoJs.enc.Utf8)
			    },
			getCodeImg() { //获取图形验证码
				let that = this;
				that.$http({
					url: that.$http.adornUrl('picture/getCodeForH5'),
					method: 'post',
					params: that.$http.adornParams({
						'uuid': this.uuid,
					})
				}).then(({
					data
				}) => {
					if (data.status) {
						//this.codeImg = data.data.base64Str
						that.$refs.img.setAttribute("src", "data:image/png;base64," + data.data.base64Str)
					}
				})
			},
		
			getCaptcha() {
			      let _this = this;
			if(this.dataForm.account==''){
				this.$message.warning('请输入用户名')
				return
			}
			 if(this.dataForm.password==''){
			 	this.$message.warning('请输入密码')
			 	return
			 }   
			          this.$Captcha.show();
			          this.$bus.$on("getTicket", (res) => {
			
			            if (res.ret === 0) {
							  _this.ticket = res.ticket
							  	   _this.uuid= getUUID()
			              let params = {
			                randstr: res.randstr,
			                ticket: res.ticket,
								uuid: _this.uuid,
			              };
						  console.log(params)
			              _this
			                .$http({
			                  url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
			                  method: "post",
			                  data: Qs.stringify(params),
			                })
			                .then(({ data }) => {
			      
							  let captchaCode = JSON.parse(data.data).CaptchaCode;
							  if (data.data && captchaCode === 1) {
							    //登录接口
							    _this.dataFormSubmit();
							  } else {
							    _this.$message.error("滑块校验未通过，请重新尝试");
							  }
			                });
			            } else {
			              // 提示用户完成验证
			            }
			            this.$bus.$off("getTicket");
			          });
			     
			    },
			    // 提交表单
			   //  dataFormSubmit() {
			   
			   //    this.$refs["dataForm"].validate((valid) => {
			   //      if (valid) {
			   //        let param = {
			   //          account: this.dataForm.account,
			   //          password: MD5(this.dataForm.password),
			   //          roleType: 1,
			   //          unionId: this.doctorUnionId,
			   //        };
			   //        localStorage.removeItem("token");
			   //        this.$http({
			   //          url: this.$http.adornUrl("/organBusiness/login"),
			   //          method: "post",
			   //          data: Qs.stringify(param),
			   //        }).then(({ data }) => {
			   //          if (data.status) {
			   //            localStorage.userInfo = JSON.stringify(data.data.user);
			   //            localStorage.dept = JSON.stringify(data.data.dept);
			   //            localStorage.setItem("token", data.data.token);
			   //             this.$cookie.set("hospitalId", data.data.user.deptPid);
			
			   //            this.getGzsInfo();
			   //          } else {
			   //            this.$message.error(data.msg);
			   //          }
			   //        });
			   //      }
			   //    });
			   //  },
			   dataFormSubmit() {
			
			   			var data = {
			   							'intParam1': 1,
			   							'stringParam1': this.dataForm.account,
			   							'stringParam2':this.dataForm.password,
			   							stringParam3: this.uuid,
			   							stringParam4: this.ticket
			   			
			   						}
			   
			   		
			   			
			   					this.$httpAes({
			   						url: this.$httpAes.adornUrl('/user/login'),
			   						method: 'post',
			   						
			   						data: data,
			   						contentType:'json',
			   						headers: {
			   							'Content-Type': 'application/json',
			   				
			   						},
			   						//params:encrypt(params)
			   					}).then(({
			   						data
			   					}) => {
			   				
			   							if(this.jzmm){
											sessionStorage.dataForm =  JSON.stringify(this.dataForm)
			   								
			   							}else if(sessionStorage.dataForm){
											
												sessionStorage.removeItem('dataForm')
										}
			   								if(data.status){
			   									
			   									var responseData = data.data;
			
			   									
			   									localStorage.token = responseData.token
			   									this.$cookie.set('token',responseData.token)
			   									sessionStorage.userInfo = JSON.stringify(responseData.user)
												localStorage.userInfo = JSON.stringify(responseData.user)
			   									sessionStorage.type = responseData.type
			   									  this.$router.push({ name: "home" });
			   									// 							const {
			   									// 								href
			   									// 							} = this.$router.resolve({
			   									// 								name: "home",
			   									// 							});
			   									// 							window.open(href, '_blank');
			   							
			   								}else{
			   									this.$message.error(data.msg)
			   								}
			   					
			   
			   					}).catch(() => {
			   						//this.getCaptcha()
			   					})
			   				
			   			
			   		},
			getPermission() {
				http({
					url: http.adornUrl('permission/userList'),
					method: 'get',
					headers: {
						'token': Vue.cookie.get('token')
					},
					params: http.adornParams()
				}).then(({
					data
				}) => {
					if (data && data.status) {
						let buttonArr = []
						getButton(data.list, buttonArr)
						changeData(data.list)
						let menuArr = []
						for (let i = 0; i < data.list.length; i++) {
							menuArr.push(data.list[i].name)
						}
						sessionStorage.setItem('buttonArr', JSON.stringify(buttonArr))
						sessionStorage.setItem('menuArr', JSON.stringify(menuArr))
						if(localStorage.isUpdate == 1){
							this.$router.replace({
								name: 'pwd-change'
							})
						}else{
							this.$router.replace({
								name: 'home'
							})
						}
						

					}
				})
			},
			clickitem(index) {
				console.log(index)
				index == this.radio ? this.radio = '' : this.radio = index,
					console.log(this.radio)
			},
			getSmsCode() { //获取短信验证码
				this.codeTime = 60;
				let data = {
					mobile: this.dataForm.mobile,
					uuid: this.uuid,
					code: this.dataForm.code
				}
				this.$http({
					url: this.$http.adornUrl('bind/sms'),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: Qs.stringify(data),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.codeTimeFun();
						this.$message.success(data.msg)
					} else {
						this.$message.error(data.msg)
					}
				})
			},
			codeTimeFun() { //验证码倒计时
				var that = this;
				that.isShowTimer = true;
				var timer = setInterval(() => {
					if (that.codeTime <= 0) {
						clearInterval(timer);
						that.isShowTimer = false;
					} else {
						that.codeTime--;
					}
				}, 1000);
			},
		}
	}

	function changeData(arr) {
		for (var i = 0; i < arr.length; i++) {
			arr[i].list = arr[i].children
			arr[i].menuId = arr[i].id
			arr[i].url = arr[i].resourceUrl
			if (arr[i].children.length != 0) {
				changeData(arr[i].children)
			}
		}
	}

	function getButton(arr, buttonArr) {
		for (var i = 0; i < arr.length; i++) {

			if (arr[i].children.length != 0 || arr[i].name == '熵孵化') {

				for (var j = 0; j < arr[i].children.length; j++) {
					if (arr[i].children[j].children.length != 0) {
						for (var k = 0; k < arr[i].children[j].children.length; k++) {
							if (arr[i].children[j].children[k].handle != null) {
								buttonArr.push(arr[i].children[j].children[k].handle)
								arr[i].children[j].children.splice(k, 1)
								k--
							}
						}
					} else if (arr[i].children[j].handle != null) {
						buttonArr.push(arr[i].children[j].handle)
						arr[i].children.splice(j, 1)
						j--
					}
				}
			} else if (arr[i].handle != null) {
				buttonArr.push(arr[i].handle)

				arr.splice(i, 1)
				i--
			}
		}
	}
</script>

<style lang="scss">

.jsws{
	text-align: center;
	height: .45rem;
	font-size: .32rem;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #A767FD;
	line-height: .45rem;
}
.loginFormBox{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.icon{
	width: .2rem;
	height: .2rem;
	margin-left: 8px;
	margin-right:8px;
}
.myCheckbox{
	width: 16px;
	height: 16px;
	margin-right: 15px;
	margin-top: -15px;
}
.jzmm{
	
	margin-left: -.02rem;
	width: 3.68rem;
	display: flex;
	align-items: center;
	height: .22rem;
	font-size: .14rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: .22rem;
	margin-bottom: .30rem;
	
}
input{
	width: 2.8rem;
	height: .24rem;
	font-size: .16rem;
	border: none;
	outline: none;
	
}
.loginButton{
	width: 3.68rem;
	height: .44rem;
	line-height:  .44rem;
	background: #A767FD;
	border-radius: .02rem;
	text-align: center;
	font-size: .16rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}
.inputArea{
	text-align: center;
	width: 3.68rem;
	height: .44rem;
	margin-bottom: .26rem;
	line-height: .44rem;
	background: #FFFFFF;
	border-radius: 4px;
	
border: 1px solid #D9D9D9;
	display: flex;
	align-items: center;
}
.yhdl{
	height: .3rem;
	font-size: .22rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: .3rem;
	text-align: center;
	margin-top: .64rem;
	margin-bottom: .4rem;
}
	.login2 {

		height: 100vh;

		display: flex;
		justify-content: space-around;

		margin: 0px;
		.el-form-item__error{
			font-size: 40px;
			padding-bottom: 30px;
			padding-top: 30px;
		}

	
		.el-input__prefix img {
			width: 90px;
			height: 90px;
		}

		input::placeholder {
			color: rgba(182, 194, 206, 1);
		}

		.title {
			// height: 3.5rem;
			font-size:  78px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: rgba(34, 34, 34, 1);
			// line-height: 5.4rem;
		}

		.tip {
			// height: 2.5rem;
			font-size: 60px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: rgba(34, 34, 34, 1);
			// line-height: 5.4rem;
			width: 100%;
			margin-top: 60px;
			text-align: center;
		}

		.code {
			width: 840px;
			height: 210px;
			background: rgba(243, 248, 254, 1);
			border-radius: 34px;
			border: none;
			padding-left: 150px;
			line-height: 210px;
			outline: none;
			font-size: 60px;
		}

		.code-img {
			width: 570px;
			height: 210px;
		}

		.code-time {
			width: 270px;
			height:210px;
			background: rgba(243, 248, 254, 1);
			border-radius: 34px;
			text-align: center;
			line-height: 210px;
		}



		.mobileTop {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		.mobileTitle {
			width: 80% !important;
			// margin-left: -140px !important;
			font-size: 80px;
			text-align: center;
		}

		.mobileTitle2 {
		
				width: 80% !important;
				// margin-left: -140px !important;
				font-size: 50px;
				text-align: center;
		}

		.loginBag {

			background: url(~@/assets/img/login/login.png) no-repeat;
			background-size: 100% 100%;
			background-attachment: fixed;
		}

		
		.login-box {
			// 		position: absolute;
			// 		left: 50%;
			// 		transform: translateX(-50%);
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: 100%;
			// padding-top: ;
		}

		.el-input__prefix {
			left: 24px;
			-webkit-transition: all .3s;
			transition: all .3s;
			align-items: center;
			display: flex;
		}

		.info .el-input__inner {
			// height: 5rem;
			width: 1476px;
			height: 198px;
			background: rgba(243, 248, 254, 1) !important;
			border-radius: 34px;
			border: 0px;
			padding-left: 180px;
			font-size: 60px !important;
		}

		.info .el-icon-user-solid:before {
			content: "\E7A5";
			font-size: 60px;
			margin-top: 5px;
		}

		.info .el-icon-lock:before {
			content: "\E6E5";
			font-size: 60px;
		}

		.info .el-input__icon {
			margin-top: 5px;
		}



		.fogpwd {
			height: 54px;
			font-size: 54px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: rgba(0, 153, 255, 1);
			cursor: pointer;
		}

	



		// 	img {
		// 		width: 144px;
		// 	}

		.login-box .top {
			margin-bottom: .4rem;
			text-align: center;
		}

		.login-box .top .logo {
			font-size: 0;
		}

		.login-box .top .company {
			font-size: .16rem;
			margin-top: .1rem;
		}

		.login-box .mid {
			font-size: 60px;
			display: flex;
			justify-content: space-around
		}

		.login-box .mid .item-btn {
			margin-top: 78px;
			width: 1476px;
			height: 198px;
			background-color: #0099FF;
			border-radius: 33px;
			font-size: 90px;
			font-weight: 400;
			color: rgba(255, 255, 255, 1);
			text-align: center;
			line-height:198px;
			cursor: pointer;
		}



		// .info {
		// 	// width: 41rem;

		// }

		.login-captcha {
			height: .4rem;
		}

		
	}

	
	
	
	
	.login .el-input__prefix img {
		width: .3rem;
		height: .3rem;
	}

	.login input::placeholder {
		color: rgba(182, 194, 206, 1);
	}

	.title {
		// height: 3.5rem;
		font-size: .26rem;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: rgba(34, 34, 34, 1);
		// line-height: 5.4rem;
	}

	.login .tip {
		// height: 2.5rem;
		font-size: .2rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: rgba(34, 34, 34, 1);
		// line-height: 5.4rem;
		width: 100%;
		margin-top: .2rem;
		text-align: center;
	}

	.code {
		width: 2.82rem;
		height: .69rem;
		background: rgba(243, 248, 254, 1);
		border-radius: 34px;
		border: none;
		padding-left: .5rem;
		line-height: .69rem;
		outline: none;
		font-size: .2rem;
	}

	.code-img {
		width: 1.9rem;
		height: .69rem;
	}

	.code-time {
		width: 1.9rem;
		height: .69rem;
		background: rgba(243, 248, 254, 1);
		border-radius: 34px;
		text-align: center;
		line-height: .69rem;
	}

	.login {
		// 		width: 100%;
		height: 100vh;
		// 		background: url(~@/assets/img/login.png) no-repeat;
		// 		background-size: cover;
		// 		position: fixed;
		display: flex;
		// justify-content: space-around;

		margin: 0px;
		// background: url(~@/assets/img/login.png) no-repeat;
		// background-size: 100% 100%;
		// background-attachment: fixed;
	}

	.mobileTop {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.mobileTitle {
		width: 80% !important;
		// margin-left: -140px !important;
		font-size: 60px;
		text-align: center;
	}

	.mobileTitle2 {
		width: 470px !important;
		// margin-left: -140px !important;
	}

	.loginBag {

		background: url(~@/assets/img/login/login.png) no-repeat;
		background-size: 100% 100%;
		background-attachment: fixed;
	}



	.login .login-box {
		// 		position: absolute;
		// 		left: 50%;
		// 		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 37%;
		// padding-top: 204px;
		padding-top: 2rem;
	}

	.login .el-input__prefix {
		left: 24px;
		-webkit-transition: all .3s;
		transition: all .3s;
		align-items: center;
		display: flex;
	}

	.info .el-input__inner {
		// height: 5rem;
		width: 4.92rem;
		height: .69rem;
		background: rgba(243, 248, 254, 1) !important;
		border-radius: 34px;
		border: 0px;
		padding-left: .6rem;
		font-size: .2rem !important;
	}

	.info .el-icon-user-solid:before {
		content: "\E7A5";
		font-size: .2rem;
		margin-top: 5px;
	}

	.info .el-icon-lock:before {
		content: "\E6E5";
		font-size: .2rem;
	}

	.info .el-input__icon {
		margin-top: 5px;
	}



	.fogpwd {
		height: .18rem;
		font-size: .18rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: rgba(0, 153, 255, 1);
		cursor: pointer;
	}




	// 	.login img {
	// 		width: 144px;
	// 	}

	.login .login-box .top {
		margin-bottom: .4rem;
		text-align: center;
	}

	.login .login-box .top .logo {
		font-size: 0;
	}

	.login .login-box .top .company {
		font-size: .16rem;
		margin-top: .1rem;
	}

	.login .login-box .mid {
		font-size: .14rem;
		display: flex;
		justify-content: space-around
	}

	.login .login-box .mid .item-btn {
		margin-top: .26rem;
		width: 4.92rem;
		height: .66rem;
		background-color: #0099FF;
		border-radius: 33px;
		font-size: .3rem;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		text-align: center;
		line-height: .66rem;
		cursor: pointer;
	}



	// .info {
	// 	// width: 41rem;

	// }

	.login-captcha {
		height: .4rem;
	}

	.bottom {
		position: absolute;
		bottom: .44rem;
		width: 100%;
		height: .17rem;
		font-size: .12rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: .17rem;
		text-align: center;
	}
	.bottom2{
		position: absolute;
		bottom: .17rem;
		width: 100%;
		height: .17rem;
		font-size: .12rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: .17rem;
		text-align: center;
	}
</style>
