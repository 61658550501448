   // const baseURL = 'http://192.168.2.5:9090/'; 
// 测试
// const baseURL = 'https://ky.docteams.cn/apidoctor';
//  const dominUrlvform = 'https://ky.docteams.cn/devform';
//  const dominPatient = 'https://ky.docteams.cn/devpatient/';
//  const dominDoctor =  'https://ky.docteams.cn/devdoctor/';
//正式
const baseURL = 'https://psy-oms.docteams.cn/apis/';
//  const baseURL = 'https://psy-oms.docteams.cn/test/';
  //  const baseURL = 'https://pro.docteams.cn/api/';
  
  // const baseURL = 'http://192.168.2.5:9090/';
 // const dominPatient = 'https://pro.docteams.cn/patient/';
 // const dominDoctor =  'https://pro.docteams.cn/doctor/';
 // const dominUrlvform = 'https://pro.docteams.cn/vform'
 const dominPatient = 'https://psy-oms.docteams.cn/patient/';
 const dominDoctor =  'https://psy-oms.docteams.cn/doctor/';
 const dominUrlvform = 'https://psy-oms.docteams.cn/vform';
// 测试跳转表单配置

// 正式跳转表单配置
 const imgUrl = 'https://aibiboat.oss-cn-shanghai.aliyuncs.com/'; //鉴权图片预览地址
const dominUrl = "https://psy.docteams.cn";

const dominUrl2 = 'https://psy-oms.docteams.cn/apis/';
const downloadUrl = "https://aibiboat.oss-cn-shanghai.aliyuncs.com/";
export default {
  baseURL,
  imgUrl,
  dominPatient,
  dominDoctor,
  dominUrlvform,
  dominUrl,

  dominUrl2,
  downloadUrl
}